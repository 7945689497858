<template>
  <v-row>
    <v-col cols="12" class="text-center font-weight-medium">
      {{ basket.code }} -
      <span class="error--text text-h5">{{ orderData.company_name }}</span> -
      {{ orderData.employee_name }}
    </v-col>
    <v-col cols="8">
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.image") }}
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="max-width: 300px"
              >
                {{ $t("labels.goods") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.sku") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.barcode") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.request") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.quantity") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.note") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="lastScanItem" class="text-center">
              <td>
                <ImageViewer
                  v-if="lastScanItem.url_images"
                  :url="lastScanItem.url_images"
                />
              </td>
              <td style="max-width: 300px">
                <div>{{ lastScanItem.name }}</div>
              </td>
              <td>{{ lastScanItem.sku }}</td>
              <td>{{ lastScanItem.customer_goods_barcode }}</td>
              <td class="text-h4">{{ lastScanItem.quantity }}</td>
              <td
                class="text-h4"
                :class="{
                  'error--text':
                    lastScanItem.quantity != scannedQuantity(lastScanItem),
                }"
              >
                {{ scannedQuantity(lastScanItem) }}
              </td>
              <td
                class="font-italic"
                :style="lastScanItem.comment_packing ? 'width: 100px' : ''"
              >
                {{ lastScanItem.comment_packing || "" }}
              </td>
            </tr>
            <tr
              v-for="(item, index) in otherItems"
              :key="`goods_${index}_${item.id_goods}`"
              class="text-center"
            >
              <td>
                <ImageViewer v-if="item.url_images" :url="item.url_images" />
              </td>
              <td style="max-width: 300px">
                <div>{{ item.name }}</div>
              </td>
              <td>{{ item.sku }}</td>
              <td>{{ item.customer_goods_barcode }}</td>
              <td class="text-h4">{{ item.quantity }}</td>
              <td
                class="text-h4"
                :class="{
                  'error--text': item.quantity != scannedQuantity(item),
                }"
              >
                {{ scannedQuantity(item) }}
              </td>
              <td
                class="font-italic"
                :style="item.comment_packing ? 'width: 100px' : ''"
              >
                {{ item.comment_packing || "" }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="4">
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.packaging") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.quantity") }}
              </th>
              <th role="columnheader" class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in packaging"
              :key="`bb_${item.id}_${key}`"
              class="text-center"
            >
              <td class="text-h4">{{ item.barcode }}</td>
              <td class="text-h4">{{ item.quantity }}</td>
              <td>
                <v-btn small color="error" @click="removePackaging(item.id)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "PackingItems",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    basket: {
      type: Object,
      default: () => {},
    },
    command: {
      type: Object,
      default: () => {},
    },
    scannedItems: {
      type: Array,
      default: () => [],
    },
    packaging: {
      type: Array,
      default: () => [],
    },
    lastIdGoods: {
      type: [Number, String],
      default: () => null,
    },
  },
  data: () => ({
    goods: [],
    items: [],
    orderData: {},
  }),
  computed: {
    lastScanItem() {
      if (!this.goods || this.goods.length === 0 || !this.lastIdGoods) {
        return null;
      }
      return [...this.goods].find((g) => g.id_goods == this.lastIdGoods);
    },
    otherItems() {
      if (!this.goods || this.goods.length === 0) {
        return [];
      }
      if (!this.lastIdGoods) {
        return [...this.goods];
      }
      return [...this.goods].filter((g) => g.id_goods != this.lastIdGoods);
    },
  },
  mounted() {
    this.getGoodsByOrder();
  },
  methods: {
    async getGoodsByOrder() {
      const { data } = await httpClient.post(
        "/order-online-packing-get-items",
        {
          id: this.order.id,
        }
      );
      this.orderData = { ...data.order };
      const goods = [];
      data.goods.forEach((io) => {
        const checkio = goods.findIndex((io3) => io3.id_goods == io.id_goods);
        io.quantity = +io.quantity;
        if (checkio < 0) {
          goods.push(io);
        } else {
          goods[checkio].quantity += io.quantity;
        }
      });
      this.goods = [...goods];
    },
    scannedQuantity(item) {
      const sItem = [...this.scannedItems].find(
        (s) => s.id_goods == item.id_goods
      );
      if (!sItem) {
        return 0;
      }
      return sItem.quantity;
    },
    removePackaging(id) {
      this.$emit("removePackaging", id);
    },
  },
};
</script>

<style scoped></style>
